import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Auth0Provider } from "@auth0/auth0-react";
import history from "./utils/history";
import { environment } from "./utils/environment";

const onRedirectCallback = (appState) => {
  history.push(appState && appState.returnTo ? appState.returnTo : "/homepage");
};

const { auth } = environment();

ReactDOM.render(
  <Auth0Provider
    domain={auth.domain}
    clientId={auth.clientId}
    audience={auth.audience}
    redirectUri={window.location.origin}
    onRedirectCallback={onRedirectCallback}
  >
    <App />
  </Auth0Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
