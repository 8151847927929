async function CallApi(token, api, path, code, method = "GET", body) {

  if (body) {
    body = JSON.stringify(body);
  }
  
  code = path.includes('?') ? `&code=${code}` : `?code=${code}`;
  return window
    .fetch(`${api}/${path}${code}`, {
      method,
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "application/json;charset=UTF-8",
      },
      body,
    })
    .then(async (response) => {
      const data = await response.json();
      if (response.ok) {
        if (data) {
          return data;
        } else {
          return Promise.reject(new Error("No data returned"));
        }
      } else {
        const error = {
          status: response.status,
          statusText: response.statusText, //statusText for 500 (no bearer) // data?.errors?.map((e) => e.message).join("\n"),
          message: data?.Message || response.statusText
        };
        return Promise.reject(error);
      }
    });
}

export { CallApi };
