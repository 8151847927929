import React, { useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  // Label,
  Input,
  FormFeedback,
} from "reactstrap";
import { CallApi } from "../../utils/api";
import { environment } from "../../utils/environment";

const EditPost = ({ token, intialPost = {}, onSave, onCancel }) => {
  const initialState = {
    status: "new",
  };
  const [state, setState] = useState(initialState);
  const [post, setPost] = useState(intialPost);

  function handleSubmit(e) {
    e.preventDefault();
    setState({ ...state, status: "working" });
    const { api, functionCodes } = environment();

    CallApi(token, api, "SavePost", functionCodes.post, "POST", post)
      .then(
        (data) => {
          onSave(data);
        },
        (error) => {
          console.log(error);
          setState({ ...state, error, status: "error" });
        }
      )
      .catch((error) => {
        setState({ ...state, error, status: "error" });
      });
  }

  function handleInputChange(e) {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setPost({
      ...post,
      [name]: value,
    });
  }

  function handleInvalid(e) {
    setState({ ...state, status: "invalid" });
  }

  function handleCancel() {
    setState(initialState);
    setPost(intialPost);
    onCancel();
  }

  return (
    <>
      <Form onSubmit={handleSubmit} onInvalid={handleInvalid}>
        {!post.parentPostId && (
          <FormGroup>
            {/* <Label for="title">Title*</Label> */}
            <Input
              type="text"
              name="title"
              id="title"
              placeholder="title (required)"
              value={post.title || ''}
              onChange={handleInputChange}
              required
              invalid={
                state.status !== "new" &&
                post.title === "" &&
                !post.parentPostId
              }
            />
            <FormFeedback invalid="true">*Required</FormFeedback>
          </FormGroup>
        )}
        <FormGroup>
          {/* <Label for="body">Body*</Label> */}
          <Input
            type="textarea"
            name="body"
            id="body"
            placeholder="text (required)"
            value={post.body}
            onChange={handleInputChange}
            required
            invalid={state.status !== "new" && post.body === ""}
          />
          <FormFeedback invalid="true">*Required</FormFeedback>
        </FormGroup>
        <Button type="submit" disabled={state.status === "working"}>
          Save
        </Button>
        <Button onClick={handleCancel} color="link">
          Cancel
        </Button>
      </Form>
      {/* {JSON.stringify(post)} */}
    </>
  );
};

export default EditPost;
