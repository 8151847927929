import React from "react";
import SignIn from "../components/SignIn";

const Home = () => (
  <div className="text-center">
    <h1 className="mb-4">Confab</h1>

    <p className="lead">
      No trolls. Respect &amp; accountability. Let's start a conversation...
    </p>

    <SignIn />

  </div>
);

export default Home;
