function environment() {
  const current = window.location.hostname.toLowerCase();

  // local
  if (current.includes("localhost")) {
    return {
      auth: {
        domain: "productivity-dev.us.auth0.com",
        clientId: "xM5EgXqqPn4b0ByUqR3hwUQjAMvu83k9",
        audience: "https://productivity-dev.us.auth0.com/api/v2/",
      },
      api: "http://localhost:7071/api",
      functionCodes: {
        homepage: '',
        page: '',
        post: '',
        signup: '',
        addToGroup: '',
      },
    };
  }

  // production
  if (current.includes("razorsoftware") || current.includes("thankful-ocean-08a5b2910.azurestaticapps.net")) {
    return {
      auth: {
        domain: "razor-software.us.auth0.com",
        clientId: "8ordZhZUGWNeyxCgyq7HiXiB7GjvkJGK",
        audience: "https://razor-software.us.auth0.com/api/v2/",
      },
      api: "https://razorconfabapi.azurewebsites.net/api",
      functionCodes: {
        homepage: 'nJi7nENQVR6BEdXh6FAwJxBWwywBCaP/UuJbkPG6PMVeWGe5i/Lg6g==',
        page: 'nJPohCHUVXq66SpRYuneJR9bAva8nXvveOotBdlqgJPaIcNZoCFaUg==',
        post: '5yqRGIs167dNtjAF/dyZGZuuLGB7uPoCazceMWwdq03lk02wvDgHUg==',
        signup: 'szBLkYstalGX2fkMUaCDvnAwCSyVkm/wrbpK6qoFYm/wa50rqt2yhg==',
        addToGroup: '',
      },
    };
  }

  // staging
  if (current.includes("azurestaticapps")) {
    return {
      auth: {
        domain: "productivity-dev.us.auth0.com",
        clientId: "xM5EgXqqPn4b0ByUqR3hwUQjAMvu83k9",
        audience: "https://productivity-dev.us.auth0.com/api/v2/",
      },
      api: "https://razorconfabapistage.azurewebsites.net/api",
      functionCodes: {
        homepage: 'x39GuJskNeEDik9Mik2rSR0izSEl/H5HRC2iYOI1CzqtCBaAQL9vtw==',
        page: 'tdLQz6DV7WjEMyfR5a2G/phdhR1YYO0YMVTcr7ToaawbLiGGVdbaaQ==',
        post: 'Kis92qdta8xqMbyAa5USspqoctV5AYP8NEFUpeR1WT9pGeU1Obj5TQ==',
        signup: 'kM9pNReW52FIEYBeycBkjtLg9xv/r9bXGrqJnwES2MmhoPC1EnZ9lg==',
        addToGroup: '',
      },
    };
  }

  //default
  return {
    api: "",
    auth: {
      domain: "",
      clientId: "",
      audience: "",
    },
  };
}

export { environment };
