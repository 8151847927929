import React, { useState, useEffect } from "react";
import { Container, Row, Col, Alert } from "reactstrap";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useLocation, useParams } from "react-router-dom";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SignalRService } from "../utils/signalRService";

import Loading from "../components/Loading";
import { CallApi } from "../utils/api";
import EditPost from "../components/page/EditPost";
import DisplayPost from "../components/page/DisplayPost";
import { environment } from "../utils/environment";
import { useIsMountedRef } from "../utils/useIsMountedRef";

export const Page = () => {
  const {pgId = "nope"} = useParams();
  console.log(pgId);
  const query = new URLSearchParams(useLocation().search);
  const id = query.get("id");
  const [state, setState] = useState({
    pageName: '',
    pageDescription: '',
    pageBlurb: '',
    error: null,
  });
  const [token, setToken] = useState("");
  const [admins, setAdmins] = useState([]);
  const [followers, setFollowers] = useState([]);
  const [posts, setPosts] = useState([]);
  const [comments, setComments] = useState([]);

  const [showNewPost, setShowNewPost] = useState(false);
  const [newPost, setNewPost] = useState();

  const { getAccessTokenSilently } = useAuth0();
  const isMountedRef = useIsMountedRef();

  useEffect(() => {
  const { api, functionCodes } = environment();

    getAccessTokenSilently()
    .then((tkn) => {
      if (isMountedRef.current) {
        setToken(tkn);
        
        CallApi(tkn, api, `Page?pageId=${id}`, functionCodes.page)
        .then(
          (data) => {
            const {userId, pageName, pageDescription, pageBlurb, admins, followers, posts} = data;
            setState({pageName, pageDescription, pageBlurb});
            setAdmins(admins);
            setFollowers(followers);
            //setPosts(posts.filter(x => !x.parentPostId).sort(postSort));
            setPosts(posts.filter(x => !x.parentPostId));
            setComments(posts.filter(x => x.parentPostId));

            SignalRService(tkn, userId, "newMessage", addNewPost)
            .then(() => {
              const groupRequest = {
                connectionId: '',
                userId: userId,
                pageId: id
              };
              CallApi(tkn, api, "addToGroup", functionCodes.addToGroup, "POST", groupRequest)
              .then(
                (success) => {
                  console.log('addToGroup:' + success);
                },
                (error) => {
                  console.log(error);
                  // setState({ error });
                }
              )
            });
          },
          (error) => {
            console.log(error);
            // setState({ error });
          }
        )
        .catch((error) => {
          console.log(error);
          // setState({ error });
        });

      }
    });
// eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [ id, getAccessTokenSilently, isMountedRef ]); 

  useEffect(() => {
    //TODO: update mainPost.latestDate
    if (isMountedRef.current && newPost) {
      newPost.new = true;
      if (newPost.parentPostId) {
        const commentsCopy = [...comments];
        commentsCopy.unshift(newPost);
        setComments(commentsCopy);
      } else {
        const postsCopy = [...posts];
        postsCopy.unshift(newPost);
        //setPosts(postsCopy.sort(postSort));
        setPosts(postsCopy);
      }
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ newPost, isMountedRef ]); 

  // function postSort(a, b) {
  //   let comparison = 0;
  //   if (a.latestPost > b.latestPost) {
  //     comparison = -1;
  //   } else if (a.latestPost < b.latestPost) {
  //     comparison = 1;
  //   }
  //   console.log({ a: a.latestPost, b:b.latestPost, comparison:comparison});

  //   return comparison;
  // }
  function doShowNewPost(e) {
    e.preventDefault();
    setShowNewPost(true);
  }
  function addNewPost(newPostFromHub) {
    const data = JSON.parse(newPostFromHub);
 // eslint-disable-next-line 
     if (data.pageId == id) {
      setNewPost(data);
    }
  }
  function handleSave() {
    setShowNewPost(false);
  }
  function handleCancel() {
    setShowNewPost(false);
  }
    
  if (state.error) {
    return <div>{state.error}</div>;
  }

  return (
    <Container className="mb-5">
    <Row>
      <Col md>
        <div className="header">
          <h2>{state.pageName}</h2>
          <p>{state.pageBlurb}</p>
        </div>
        <Button color="link" onClick={doShowNewPost}>
          <FontAwesomeIcon icon="reply" className="mr-2 rot180" /> Add New Post
        </Button>
        {showNewPost && (
          <EditPost
            token={token}
            intialPost={{ pageId: id }}
            onSave={handleSave}
            onCancel={handleCancel}
          />
        )}
        {/* posts: {JSON.stringify(posts)} */}
        {posts && (posts.map((post) => {
          return <DisplayPost key={post.id} token={token} post={post} allComments={comments} />;
        }))}
      </Col>
      <Col md={3}>
        <div className="tray">
          <p dangerouslySetInnerHTML={{__html: state.pageDescription}} />
          <h5>Feedback</h5>
          <Alert color="danger"><strong>Errors:</strong> Please email a screenshot if possible and 
            any information about what happened and what you were doing just before the error.</Alert>
          <p><strong>Questions, comments and requests/ideas:</strong>  I wanna hear them - send an email</p>
          <Alert color="primary"><strong><a href="mailto:candeeschlicht@gmail.com">candeeschlicht@gmail.com</a></strong></Alert>
        </div>
        <h5>Admins</h5>
        <ul>
          {admins && (admins.map((x) => {
            return <li key={x}>{x}</li>;
          }))}
        </ul>
        <h5>Members</h5>
        <ul>
          {followers && (followers.map((x) => {
            return <li key={x}>{x}</li>;
          }))}
        </ul>
      </Col>
    </Row>
  </Container>
  );
};

export default withAuthenticationRequired(Page, {
  onRedirecting: () => <Loading />,
});
