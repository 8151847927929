import React from "react";
import { Button } from "reactstrap";

import { useAuth0 } from "@auth0/auth0-react";

function SignInUnAuthenticated(loginWithRedirect) {
  return (
    <div className="text-center hero my-5">
      <p className="lead">
        To request access email{" "}
        <a href="mailto:confab@razorsoftware.net">confab@razorsoftware.net</a>
      </p>

      <p className="lead">Sign In or Sign Up using an access code</p>
      <Button
        id="loginBtn"
        color="primary"
        className="btn-margin"
        onClick={() => loginWithRedirect()}
      >
        Sign up/in
      </Button>
    </div>
  );
}

function SignInAuthenticated() {
  return (
    <div className="text-center hero my-5">
      <p className="lead">Welcome back!</p>
      <Button
        href="/homepage"
        id="loginBtn"
        color="primary"
        className="btn-margin"
      >
        Go home
      </Button>
    </div>
  );
}

const SignIn = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  return isAuthenticated
    ? SignInAuthenticated()
    : SignInUnAuthenticated(loginWithRedirect);
};

export default SignIn;
