import React, { useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  FormFeedback,
  Toast,
  ToastBody,
} from "reactstrap";
import { CallApi } from "../utils/api";
import { environment } from "../utils/environment";

const SignUp = ({ token, onSignUp }) => {
  const [state, setState] = useState({
    status: "new",
  });
  const [follower, setFollower] = useState({
    firstName: "",
    lastName: "",
    email: "",
    inviteCode: "",
  });

  function handleSubmit(e) {
    e.preventDefault();
    setState({ ...state, status: "working" });
    const { api, functionCodes } = environment();

    CallApi(token, api, "SignUp", functionCodes.signup, "POST", follower)
      .then(
        (data) => {
          console.log(data);
          onSignUp(data);
        },
        (error) => {
          console.log(error);
          setState({ ...state, error, status: "error" });
        }
      )
      .catch((error) => {
        console.log(error);
        setState({ ...state, error, status: "error" });
      });
  }

  function handleInputChange(e) {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setFollower({
      ...follower,
      [name]: value,
    });
  }

  function handleInvalid(e) {
    setState({ ...state, status: "invalid" });
  }

  return (
    <>
      <p>
        Enter your information and the code provided to you by the page owner.
        Access is currently invite only. To request access or to start a page,
        contact{" "}
        <a href="mailto:confab@razorsoftware.net">confab@razorsoftware.net</a>
      </p>
      <Form onSubmit={handleSubmit} onInvalid={handleInvalid}>
        <FormGroup>
          <Label for="firstName">First Name*</Label>
          <Input
            type="text"
            name="firstName"
            id="firstName"
            value={follower.firstName}
            onChange={handleInputChange}
            required
            invalid={state.status !== "new" && follower.firstName === ""}
          />
          <FormFeedback invalid="true">*Required</FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label for="lastName">Last Name*</Label>
          <Input
            type="text"
            name="lastName"
            id="lastName"
            value={follower.lastName}
            onChange={handleInputChange}
            required
            invalid={state.status !== "new" && follower.lastName === ""}
          />
          <FormFeedback invalid="true">*Required</FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label for="email">Email*</Label>
          <Input
            type="email"
            name="email"
            id="email"
            value={follower.email}
            onChange={handleInputChange}
            required
            invalid={state.status !== "new" && follower.email === ""} //TODO: tweak invalid for email
          />
          <FormFeedback invalid="true">*Required</FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label for="inviteCode">Code*</Label>
          <Input
            type="text"
            name="inviteCode"
            id="inviteCode"
            value={follower.inviteCode}
            onChange={handleInputChange}
            required
            invalid={state.status !== "new" && follower.inviteCode === ""}
          />
          <FormText>Code given to you by page owner</FormText>
          <FormFeedback invalid="true">*Required</FormFeedback>
        </FormGroup>
        {/* <FormGroup check>
          <Label for="agreeTOC">
            <Input type="checkbox" name="agreeTOC" id="agreeTOC" value={follower.agreeTOC} invalid={!follower.agreeTOC}></Input>  
            <span>I agree to the <a href="confab_terms_and_conditions.pdf" target="_blank"> Terms and Conditions</a></span>
          </Label>
          <FormFeedback invalid="true">You must agree to the Terms and Conditions</FormFeedback>
        </FormGroup> */}
        {state.error && (
          <div className="p-3 bg-danger my-2 rounded">
            <Toast>
              <ToastBody>
                {state.error.message}
              </ToastBody>
            </Toast>
          </div>
        )}
        <Button type="submit" disabled={state.status === "working"}>
          Sign Up
        </Button>
      </Form>
    </>
  );
};

export default SignUp;
