import { library } from "@fortawesome/fontawesome-svg-core";
import { faReply, faPowerOff, faUser, faCaretUp, faCaretDown } from "@fortawesome/free-solid-svg-icons";

function initFontAwesome() {
  library.add(faReply);
  library.add(faUser);
  library.add(faPowerOff);
  library.add(faCaretUp);
  library.add(faCaretDown);
}

export default initFontAwesome;
