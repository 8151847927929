import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../components/Loading";
import { CallApi } from "../utils/api";
import SignUp from "../components/SignUp";
import { environment } from "../utils/environment";
import { useIsMountedRef } from "../utils/useIsMountedRef";

export const HomePage = () => {
  const [token, setToken] = useState("");
  const [following, setFollowing] = useState();
  // const [posts, setPosts] = useState([]);

  const { getAccessTokenSilently } = useAuth0();
  const isMountedRef = useIsMountedRef();

  useEffect(() => {
    const { api, functionCodes } = environment();

    getAccessTokenSilently()
    .then((tkn) => {
      setToken(tkn);
      if (isMountedRef.current) {
        CallApi(tkn, api, "Homepage", functionCodes.homepage)
        .then(
          (data) => {
            setFollowing(data.following);
            // setPosts(data.posts);
          },
          (error) => {
            console.log(error);
          }
        )
        .catch((error) => {
          console.log(error);
        });
      }
    });

  }, [ getAccessTokenSilently, isMountedRef ]); 



  function DoRedirect(following) {
    return (
      <Redirect to={`/page?id=${following[0].pageId}`} />
    );
  }

  function DoSignUp() {
    function SignedUp(data) {
      const { following } = data;
      setFollowing(following);
      // setPosts(posts);
    } 

    return (
      <SignUp
        token={token}
        onSignUp={SignedUp}
      />
    );
  }

  function DoLoading() {
    return (
      <Loading />
    );
  }

  return following?.length > 0 ? DoRedirect(following)
  : following?.length === 0 ? DoSignUp()
  : DoLoading();

};

export default withAuthenticationRequired(HomePage, {
  onRedirecting: () => <Loading />,
});
