function formatDateTime(value) {
    if (value) {
        value = value + 'Z';
        return new Date(String(value)).toLocaleDateString(undefined, {
          day: 'numeric',
          month: 'numeric',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        });
      }
}

export {formatDateTime};