import { HubConnectionBuilder, LogLevel } from '@aspnet/signalr';
import { environment } from "./environment";

async function SignalRService(token, userId, target, onTargetFunc){
    const { api } = environment();
    const connection = new HubConnectionBuilder()
    .withUrl(`${api}/${userId}`, { accessTokenFactory: () => { return token }})
    .configureLogging(LogLevel.Information)
    .build();

    connection.on(target, onTargetFunc);
    // connection.on("clientConnectionConnected", (s) => {
    //     console.log('connected');
    //     console.log(s);
    // });
    connection.onclose(() => console.log('disconnected')); 

    return connection.start();

  }

export {SignalRService}; 