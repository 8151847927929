import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "reactstrap";
import {formatDateTime} from "../../utils/format";
import EditPost from "./EditPost";

const DisplayPost = ({ token, post, allComments }) => {
  const [reply, setReply] = useState(false);

  function showReply() {
    setReply(true);
  }
  function handleSave() {
    setReply(false);
  }
  function handleCancel() {
    setReply(false);
  }

  return (
    <div className="post">
      <div style={{width:'30px', float:'left', clear:'both'}}>
      {/* <FontAwesomeIcon
        icon="caret-up"
        className="mr-2"
      />
      <FontAwesomeIcon
        icon="caret-down"
        className="mr-2"
      /> */}
      </div>
      <div>
        <div className={`${post.new ? "new" : ""}`}> 
          <span>{post.author} {formatDateTime(post.createDate)}</span> {formatDateTime(post.latestPost)}
            {post.updateDate !== post.createDate && (<span>{formatDateTime(post.updateDate)}</span>)}
    {post.title && (<h5>{post.title}</h5>)}
          <p>{post.body}</p>
        </div>
        {reply && (
          <EditPost
            token={token}
            intialPost={{ pageId: post.pageId, parentPostId: post.id }}
            onSave={handleSave}
            onCancel={handleCancel}
          />
        )}

      <Button color="link" onClick={showReply} className="reply">
        <FontAwesomeIcon icon="reply" className="mr-2 rot180" /> Reply
      </Button>

        {allComments.filter(x => x.parentPostId === post.id).map((comment) => {
          // if(post.latestPost < comment.updateDate) {
          //   post.latestPost = comment.updateDate;
          //   console.log(comment.updateDate);
          // }
          return (
            <DisplayPost key={comment.id} token={token} post={comment} allComments={allComments} />
          );
        })}   

      </div>
    </div>
  );
};

export default DisplayPost;
